import React, { Component }  from "react";
import { Menu, Header, Icon, 
         Sidebar, Segment,
         List} from 'semantic-ui-react';
import {Link, Redirect} from "react-router-dom"
import Cookies from "js-cookie"

const handleLogOut = () => {
  Cookies.remove("deepstain-access")
  Cookies.remove("deepstain-refresh")
  Cookies.remove("deepstain-useremail")
  window.location.reload();
}


const ControlList = () => (
  <List size="massive" selection verticalAlign='middle'>
    <List.Item style={{padding: '2vh'}} onClick={handleLogOut}>
      <Icon size="large" name="log out" />
      <List.Content>
        <List.Header>Log Out</List.Header>
      </List.Content>
    </List.Item>
  </List>
)

const ControlPanel = (
  { visible}) => (
  <Sidebar
    as={Segment}
    direction='right'
    visible={visible}
    animation='scale down'
  >
    <div style={{padding: '1.75vh', fontSize:'3vh'}}>
      <Header>Controls</Header>
    </div>
    <ControlList/>
  </Sidebar>
)

const HomeButton = () => 
  <Link style={{paddingLeft: '3.25vh'}} to="/">
    <Menu.Item name = "home" onClick={() => {}}>
        Canvas Admin
    </Menu.Item>
  </Link>

export default class NavBar extends Component{
      state = { open: false, 
                token: JSON.parse(Cookies.get("deepstain-access")).token, 
              }

    componentDidMount(){
      this.setState({email: Cookies.get("deepstain-useremail")})
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })
 
    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {
      const {redirect} = this.state
      const {toggleProfileControl, profileControl} = this.props;
     
      return (
        redirect ? <Redirect to="/"/>:  
        <div>
          <Menu style={{paddingTop: '2vh'}} size="large" secondary>
            <HomeButton/>    
            <Menu.Menu
                style={{paddingRight: '5vh'}} secondary position='right'
                onClick={() => {this.setState({createModal: true})}}>
                <Menu.Item as={Link} to="/studies">
                  Studies
                </Menu.Item>
                <Menu.Item as={Link} to="/pipelines">
                  Pipelines
                </Menu.Item>
                <Menu.Item as={Link} to="/models">
                  Models
                </Menu.Item>
                <Menu.Item as={Link} to="/organs">
                  Organs
                </Menu.Item>
                <Menu.Item as={Link} to="/slides">
                  Slides
                </Menu.Item>
                <Menu.Item as={Link} to="/reviewers">
                  Review Pipelines
                </Menu.Item>
                <Menu.Item as={Link} to="/users">
                  Users
                </Menu.Item>
                <Menu.Item onClick={toggleProfileControl} icon as='a'>
                  <Icon size="big" name={profileControl ? 'user' : 'user outline'} />
                </Menu.Item>
                
                <ControlPanel visible={profileControl}/>
            </Menu.Menu>
          </Menu>
        </div>)
    }
  }  
