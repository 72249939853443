import React from "react";
import { Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import bkimage from '../Assets/canvaswelcomepage.png'

export default function Home() {
    return (
        <div style={{
              width: '100%',
              height: '100vh',
              backgroundImage: `url(${bkimage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'}}>
            <div style={{padding: '3vh'}} >
              <Header style={{fontSize: '15vh', color: "white"}} >              
                { "admin canvas"}
                <Header style={{fontSize: '2vh', color: "white", paddingLeft: '32vh'}} >              
                </Header>
              </Header>
            </div>
            <div style={{position: 'absolute', margin:0, left: '45%', top:'50%' }}>
              <Link to="/signin" >
                <Button circular size="massive"> 
                  Sign in
                </Button>
              </Link>
            </div>
        </div>
    );
}
  
  