// Use the .env.local file during development
// Example: 
// REACT_APP_API_URL=http://example.com/api

// During deployment set the REACT_APP_API_URL enviroment variable

let API_URL = process.env.REACT_APP_API_URL;
let FRONTEND_API_URL = process.env.REACT_APP_CANVAS_APP_URL;

export {API_URL, FRONTEND_API_URL};
